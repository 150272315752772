@import "~react-datepicker/src/stylesheets/variables";

// $datepicker__background-color: #0293cf;
//$datepicker__border-color: #cccccc;
$datepicker__muted-color: #4a4a4a;
$datepicker__header-color: #4a4a4a;
$datepicker__border-color: #bac7d5;
$datepicker__border-radius: 0;
$datepicker__font-family: "Roboto Condensed", sans-serif;
$datepicker__day-margin: 0.27rem;

.react-datepicker__day.hay-facturas {
    color: white;
    border-radius: 50%;
    display: inline-block;

    &:hover {
        border-radius: 50%;
    }
}

.react-datepicker__day.vencidas {
    background-color: #d21c1c;

    &:hover {
        background-color: #970c0c;
    }
}

.react-datepicker__day.prox-vencer {
    background-color: #f9971e;

    &:hover {
        background-color: #d28b21;
    }
}

.react-datepicker__day.sin-vencer {
    background-color: #00c49f;

    &:hover {
        background-color: #009c77;
    }
}

@import "~react-datepicker/src/stylesheets/datepicker";

.react-datepicker__day-name {
    color: $datepicker__header-color;
}

.react-datepicker__header {
    text-align: center;
    background-color: #eff2f5;
}

.react-datepicker-popper {
    z-index: 4;
}
